import React, { useState, useEffect, useCallback } from 'react';
import styles from '../molecules/Sidepanel.module.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { SecurityIcon } from '../../shared/components/atoms/SecurityIcon';
import { CommandButton, Panel, PanelType, IContextualMenuItem, ContextualMenuItemType } from 'office-ui-fabric-react';
import { ResultGraph } from '../molecules/ResultGraph';
import { ResultGraphRating } from '../molecules/ResultGraphRating';
import { ResultPersonGraph } from '../../shared/components/molecules/ResultPersonGraph';
import { AttendeesPanel } from '../molecules/AttendeesPanel';
import { IVotable, IQuestion, ISurvey, IAnswerOption, IAttendance } from '../../store/business/interfaces';
import { QuestionConfigurationIcons } from '../../shared/components/atoms/QuestionConfigurationIcons';
import { QuestionStoppedIcon } from '../molecules/QuestionStoppedIcon';
import { VotesSplittingIcon } from '../../shared/components/atoms/VotesSplittingIcon';
import { QuestionTitle } from '../atoms/QuestionTitle';
import { ConfirmationDialog } from '../atoms/ConfirmationDialog';
import { ResultPersonDetailsPanel } from '../molecules/ResultPersonDetailsPanel';
import { renderCollapsedSvg, renderExpandedSvg } from '../atoms/svg';
import { ResultRatingDetailsPanel } from '../molecules/ResultRatingDetailsPanel';
import { QuestionKindIcon } from '../../shared/components/atoms/QuestionKindIcon';

export interface IResultProps {
	questionId: string;
	survey: ISurvey;
	onClose: () => void;
	onToggleQuestion: (question: IQuestion, start: boolean) => void;
	onEdit: (question: IQuestion) => void;
	onDuplicate: (question: IQuestion) => void;
	onExport: (question: IQuestion) => void;
	onReset: (question: IQuestion) => void;
	onDelete: (question: IQuestion) => void;
	onTogglePanelSize: () => void;
}

/**
 * loads and set currentQuestion from redux store, prepares results for display: if vote was public, voters names are included, if vote was private, voters names are not displayed
 * @param props IResultProps {
	questionId: string;
	survey: ISurvey;
	onClose: () => void;
	onToggleQuestion: (question: IQuestion, start: boolean) => void;
	onEdit: (question: IQuestion) => void;
	onExport: (question: IQuestion) => void;
	onReset: (question: IQuestion) => void;
	onDelete: (question: IQuestion) => void;
	onTogglePanelSize: () => void;
}
 * @returns ResultGraphRating
 */
export const Result = (props: IResultProps) => {
	const members = useSelector((state: AppState) => state.business.members);
	const questions = useSelector((state: AppState) => state.business.questions);
	const [votable, setVotable] = useState<IVotable>();
	const [currentQuestion, setCurrentQuestion] = useState<IQuestion>();
	const [isAttendeesPanelOpen, setIsAttendeesPanelOpen] = useState<boolean>(false);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const [personDetailsId, setPersonDetailsId] = useState<string | undefined>();
	const [ratingDetailsId, setRatingDetailsId] = useState<string | undefined>();

	const [hideDeleteQuestionDialog, setHideDeleteQuestionDialog] = useState(true);
	const [hideResetQuestionDialog, setHideResetQuestionDialog] = useState(true);

	useEffect(() => {
		setCurrentQuestion(questions.find((q) => q.id === props.questionId));
	}, [props.questionId, questions]);

	useEffect(() => {
		if (votable?.type === 'question') {
			setVotableFromQuestion();
		} else if (votable?.type === 'answer') {
			setVotableFromAnswerOption(currentQuestion?.answerOptions?.find((ao) => ao.id === votable.id));
		}
	}, [members.lastChanged, currentQuestion?.loaded]);

	const setVotableFromQuestion = useCallback(() => {
		let tempVotable = {
			id: currentQuestion.id,
			votesCount: currentQuestion.votesCount,
			type: 'question',
			averageRating: currentQuestion.averageRating,
		} as IVotable;

		if (
			currentQuestion.security === 'public' &&
			(currentQuestion.kind === 'choice' || currentQuestion.kind === 'rating')
		) {
			const voteWeights = currentQuestion.answerOptions.reduce((agg, aos) => {
				aos.attendees.forEach((attendee) => {
					const votes = agg[attendee.id] ?? { id: attendee.id, voteWeight: 0, text: '', comment: '' };
					if (currentQuestion.kind === 'rating') {
						votes.voteWeight = attendee.voteWeight;
					} else {
						votes.voteWeight = votes.voteWeight + attendee.voteWeight;
					}
					votes.text = attendee.text;
					agg[attendee.id] = votes;
				});
				return agg;
			}, {} as { [id: string]: IAttendance });
			tempVotable.attendees = Object.keys(voteWeights).map((k) => voteWeights[k]);
		} else {
			tempVotable.attendees = currentQuestion.attendees;
		}
		setVotable(tempVotable);
	}, [currentQuestion]);

	const setVotableFromAnswerOption = useCallback(
		(ao: IAnswerOption) => {
			setVotable(ao);
		},
		[currentQuestion]
	);

	const onTogglePanelSize = () => {
		setIsExpanded(!isExpanded);
		props.onTogglePanelSize();
	};

	if (!currentQuestion || !props.survey) {
		return <></>;
	}

	let moreOptions: IContextualMenuItem[] = [
		{
			key: 'editQuestion',
			text: 'Bearbeiten',
			iconProps: { iconName: 'Edit' },
			onClick: () => {
				props.onEdit(currentQuestion);
			},
			disabled: currentQuestion.status !== 'new' || props.survey.readOnly,
		},
		{
			key: 'cloneQuestion',
			text: 'Duplizieren',
			iconProps: { iconName: 'DependencyAdd' },
			onClick: () => {
				props.onDuplicate(currentQuestion);
			},
		},
		{
			key: 'exportQuestion',
			text: 'Abstimmungsprotokoll exportieren',
			iconProps: { iconName: 'Download' },
			onClick: () => {
				props.onExport(currentQuestion);
			},
			disabled: currentQuestion.status !== 'stopped',
		},
		{
			key: 'seperatorQuestion',
			itemType: ContextualMenuItemType.Divider,
		},
		{
			key: 'resetQuestion',
			text: 'Ergebnisse zurücksetzen',
			iconProps: { iconName: 'Refresh' },
			onClick: () => {
				setHideResetQuestionDialog(false);
			},
			className: styles.rotateFlipIcon,
			disabled: currentQuestion.status !== 'stopped' || props.survey.readOnly,
		},
		{
			key: 'deleteQuestion',
			text: 'Abstimmung löschen',
			iconProps: { iconName: 'Delete' },
			onClick: () => {
				setHideDeleteQuestionDialog(false);
			},
			disabled: currentQuestion.status !== 'new' || props.survey.readOnly,
		},
	];

	return (
		<div className={styles.formContainer}>
			<ConfirmationDialog
				hidden={hideDeleteQuestionDialog}
				title={'Abstimmung löschen'}
				message={
					currentQuestion.title
						? `Wollen Sie die Abstimmung <b>${currentQuestion.title}</b> inkl. Antworten wirklich löschen?`
						: `Wollen Sie diese Abstimmung inkl. Antworten wirklich löschen?`
				}
				onConfirm={() => {
					setHideDeleteQuestionDialog(true);
					props.onDelete(currentQuestion);
				}}
				onDismiss={() => {
					setHideDeleteQuestionDialog(true);
				}}
			/>
			<ConfirmationDialog
				hidden={hideResetQuestionDialog}
				title={'Ergebnisse zurücksetzen'}
				message={
					currentQuestion.title
						? `Wollen Sie die Ergebnisse von <b>${currentQuestion.title}</b> wirklich zurücksetzen und alle Antworten löschen?`
						: `Wollen Sie die Ergebnisse wirklich zurücksetzen und alle Antworten löschen?`
				}
				onConfirm={() => {
					setHideResetQuestionDialog(true);
					props.onReset(currentQuestion);
				}}
				onDismiss={() => {
					setHideResetQuestionDialog(true);
				}}
			/>
			<header>
				<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
					<div className={styles.panelHeader}>
						{currentQuestion.status === 'started' ? (
							<div className={styles.stopButtonContainer}>
								<CommandButton
									title='Abstimmung stoppen'
									onClick={() => {
										props.onToggleQuestion(currentQuestion, false);
									}}
									disabled={props.survey.readOnly}
								/>
							</div>
						) : currentQuestion.status === 'stopped' ? (
							<div className={styles.stoppedContainer}>
								<CommandButton title='Abstimmung gestoppt' iconProps={{ iconName: 'SkypeCircleCheck' }} />
							</div>
						) : (
							<div className={styles.playButtonContainer}>
								<CommandButton
									title='Abstimmung starten'
									onClick={() => {
										props.onToggleQuestion(currentQuestion, true);
									}}
									disabled={props.survey.readOnly}
								/>
							</div>
						)}
						<h1>
							<QuestionTitle question={currentQuestion} />
						</h1>
					</div>
					<div className={[styles.flexContainer, styles.panelButtons].join(' ')}>
						<CommandButton
							onClick={() => {
								onTogglePanelSize();
							}}
						>
							{!isExpanded ? renderExpandedSvg() : renderCollapsedSvg()}
						</CommandButton>
						{moreOptions.length > 0 ? (
							<div className={styles.moreImageButton}>
								<CommandButton
									iconProps={{ iconName: 'More' }}
									menuIconProps={{ iconName: '' }}
									menuProps={{
										items: moreOptions,
									}}
								/>
							</div>
						) : (
							<div className={styles.emptyMoreOptionsContainer}></div>
						)}
						<CommandButton
							onClick={() => {
								props.onClose();
							}}
							iconProps={{ iconName: 'ChromeClose' }}
							className={styles.closeIcon}
						/>
					</div>
				</div>
				<div className={[styles.meta].join(' ')}>
					<div className={styles.topLeft}>
						<QuestionKindIcon question={currentQuestion} />
						<SecurityIcon question={currentQuestion} />
						<QuestionConfigurationIcons
							question={currentQuestion}
							numberOfOptions={currentQuestion.answerOptions.length}
						/>
						{members.entities.find((m) => m.voteWeight > 1) ? <VotesSplittingIcon question={currentQuestion} /> : <></>}
					</div>
					<div className={styles.topRight}>
						<CommandButton
							text={`${currentQuestion.votesCount} Teilnehmer anzeigen`}
							onClick={() => {
								setVotableFromQuestion();
								setIsAttendeesPanelOpen(true);
							}}
							className={styles.showAttendeesButton}
							iconProps={{ iconName: 'People' }}
						/>
					</div>
					{currentQuestion.status === 'stopped' ? (
						<div className={styles.bottomLeft}>
							<QuestionStoppedIcon question={currentQuestion} />
						</div>
					) : (
						<></>
					)}
				</div>
			</header>
			<main>
				{currentQuestion.security === 'private' && currentQuestion.status !== 'stopped' && (
					<p>Ergebnisse einer geheimen Wahl werden erst am Ende einer Abstimmung dargestellt.</p>
				)}
				{(currentQuestion.security === 'public' || currentQuestion.status === 'stopped') &&
					<>
						{currentQuestion.kind === 'choice' &&
							<ResultGraph
								question={currentQuestion}
								renderAppendix={(o) => (
									<>
										{(currentQuestion.security === 'public' || o.type === 'virtualNotVoted') && o.votesCount > 0 ? (
											<CommandButton
												title='Stimmen anzeigen'
												iconProps={{ iconName: 'ReceiptCheck' }}
												onClick={() => {
													setVotableFromAnswerOption(o);
													setIsAttendeesPanelOpen(true);
												}}
											/>
										) : (
											''
										)}
									</>
								)}
							/>
						}
						{currentQuestion.kind === 'text' &&
							<div className={styles.textResultContainer}>
								{currentQuestion.attendees
									.sort((a1, a2) => {
										return a1.comment?.localeCompare(a2.comment);
									})
									.map((attendee) => {
										return (
											<div className={styles.textResultOuter} key={'textResult' + attendee.id}>
												<div className={styles.textResult}>
													<div className={styles.textResultInner}>
														<div>{attendee.comment}</div>
														{currentQuestion.security !== 'private' && (
															<div className={styles.textResultInnerAttendeeName}>{attendee.text}</div>
														)}
													</div>
												</div>
											</div>
										);
									})}
							</div>
						}
						{currentQuestion.kind === 'person' &&
							<ResultPersonGraph
								key={currentQuestion.status}
								answerOptions={currentQuestion.answerOptions}
								showDetails={currentQuestion.security === 'public'}
								onClickDetails={({ id }) => {
									setPersonDetailsId(id);
								}}
								useVoteWeightInPercent={props.survey.useVoteWeightInPercent}
								sortResultsByVotes={props.survey.sortResultsByVotes}
							/>
						}
						{currentQuestion.kind === 'rating' &&
							<ResultGraphRating
								key={currentQuestion.status}
								answerOptions={currentQuestion.answerOptions}
								maxRating={currentQuestion.maxRating}
								showDetails={currentQuestion.security === 'public'}
								onClickDetails={({ id }) => {
									setRatingDetailsId(id);
								}}
								fontSize={20}
							/>
						}
					</>
				}
			</main>
			<Panel
				isLightDismiss={true}
				isOpen={isAttendeesPanelOpen}
				type={PanelType.custom}
				customWidth='100vw'
				layerProps={{ eventBubblingEnabled: true }}
				onLightDismissClick={() => {
					setIsAttendeesPanelOpen(false);
					setVotable(undefined);
				}}
			>
				<AttendeesPanel
					showVoteWeight={currentQuestion.security === 'public'}
					votable={votable}
					members={members.entities}
					onClose={() => {
						setIsAttendeesPanelOpen(false);
						setVotable(undefined);
					}}
				/>
			</Panel>
			{personDetailsId !== undefined && (
				<ResultPersonDetailsPanel
					question={currentQuestion}
					answerOption={currentQuestion.answerOptions.find((ao) => ao.id === personDetailsId)}
					onDismiss={() => {
						setPersonDetailsId(undefined);
					}}
				/>
			)}
			{ratingDetailsId !== undefined && (
				<ResultRatingDetailsPanel
					question={currentQuestion}
					answerOption={currentQuestion.answerOptions.find((ao) => ao.id === ratingDetailsId)}
					onDismiss={() => {
						setRatingDetailsId(undefined);
					}}
				/>
			)}
		</div>
	);
};
